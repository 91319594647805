@tailwind base;

@layer base {
    * {
        transition-property: background-color, border-color, color, fill, stroke,
            opacity, box-shadow, transform, filter, backdrop-filter;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 500ms;
    }

    main a {
        @apply text-secondary underline font-medium hover:text-primary;
    }

    table {
        @apply rounded shadow-lg bg-white w-full text-left;
    }

    table tbody tr {
        @apply border-b-2 border-gray-200 last:border-0 even:bg-gray;
    }

    table thead tr {
        @apply border-b-2 border-gray-200;
    }

    table th,
    table td {
        @apply py-5 text-smd pr-4 first:pl-5 last:pr-5;
    }

    table th {
        @apply font-semibold text-gray-600 bg-gray;
    }

    input,
    select {
        @apply outline-none;
    }

    input:invalid,
    input.invalid {
        @apply text-red;
    }

    .gray-inputs input:not(.react-select__input),
    .gray-inputs select,
    .gray-inputs textarea,
    .gray-inputs .css-b62m3t-container {
        @apply bg-gray !important;
    }

    input:disabled,
    select:disabled,
    .react-select--is-disabled {
        @apply bg-gray-100 text-gray-500 cursor-not-allowed !important;
    }

    .react-select__indicator {
        padding: 0 !important;
    }

    .Toastify__toast-body {
        font-family: "Poppins", san-serif;
        @apply text-sm text-gray-600 font-medium;
    }
}

@tailwind components;

@layer components {
    .manage-btn {
        @apply font-medium py-1.5 px-2 text-xxs rounded-sm shadow-md first:mr-2;
    }

    .menu-item {
        @apply py-4 px-6 block border-b-2 border-gray-200 flex items-center font-semibold;
    }

    .active-menu-item {
        @apply menu-item;
        @apply bg-gray-100 shadow font-bold;
    }

    .menu-item-icon {
        @apply inline-block py-2 shadow-md rounded text-xs mr-3 bg-bright 
        text-primary w-8 text-center group-hover:bg-primary group-hover:text-bright;
    }

    .active-menu-item-icon {
        @apply menu-item-icon;
        @apply bg-secondary text-white !important;
    }
}

@tailwind utilities;

@layer utilities {
    @variants group-hover {
        .text-shadow {
            text-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
        }
    }

    .collapse-btn {
        @apply shadow text-xs px-2.5 py-1.5 text-gray-300 bg-gray-100 rounded-l border border-r-0 border-gray-200 transition-none hover:bg-primary hover:border-primary hover:text-white;
    }

    .mx-auto-important {
        @apply mx-auto !important;
    }

    .highlighted {
        animation: highlight 2s ease-in-out 2 alternate;
    }

    @keyframes highlight {
        100% {
            @apply bg-brighter;
        }
    }
}

@media print {
    * {
        @apply invisible;
    }

    .dialog {
        @apply w-screen h-screen;
    }

    .printable {
        border: none;
        padding: 0;
    }

    .printable,
    .printable * {
        @apply visible;
    }
}
